<script setup></script>
<template>

<section class="body-font relative">
    <div class=" w-full grid place-items-center p-5">
        <p class=" text-blue-2 font-bold flex"><img :src="`${publicPath}assets/img/location.png`"> 8 Avenue Gutenberg 77600 Bussy-Saint-Georges France</p>
    </div>
    <div class="inset-0 h-60">
        <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.4668481126205!2d2.730381576754706!3d48.830233202687204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6035c138aacbb%3A0x68e8cc1fc89f74fb!2sCryopal!5e0!3m2!1sfr!2smu!4v1714375719967!5m2!1sfr!2smu" style=""></iframe>
    </div>
</section>

</template>