<script>

export default{
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    props: {
        title: String,
        text: String,
        imageUrl: String,
        url: String
    },
    methods: {
        clickProduct(name, link){
            localStorage.setItem('childrenMenu', name);
            localStorage.setItem('childrenLinkMenu', link)
        }
    }
}

</script>

<template>
  <RouterLink :to="url.startsWith('/') ? url : '/' + url" @click="clickProduct(title, url)">
<div class="flex rounded text-white p-8 bg-no-repeat bg-cover pt-60-percent height-carrousel shadow-lg shadow-blue-2 w-auto m-2 transition duration-300 ease-in-out hover:scale-110 bg-center " :style="`background-image: url('${imageUrl}')`">
  <div class="p-0 h-full content-end">
    <div class="p-3 bg-white rounded-full w-14 my-2">
      <img :src="`${publicPath}assets/img/logo_cr.png`">
    </div>
    <ul class="flex flex-col gap-4">
      <li class="flex items-center gap-4">
        <p class="line-clamp-2 block font-sans antialiased font-normal leading-relaxed text-inherit text-sm">
            {{ title }}
        </p>
      </li>
      <li class="flex items-center gap-4 border-l-2 ps-2">
        <p class="block font-sans antialiased font-normal leading-relaxed text-inherit text-xs">
            {{ text }}
        </p>
      </li>
      <li class="flex items-center gap-4">
        <a href='#' class="block font-sans antialiased leading-relaxed text-inherit text-blue-4 font-bold">
            <img :src="`${publicPath}assets/img/fleche_right.png`">
        </a>
        <RouterLink :to="url.startsWith('/') ? url : '/' + url" @click="clickProduct(title, url)" class="block font-sans antialiased leading-relaxed text-inherit text-blue-4 font-bold"> En savoir plus </RouterLink>
      </li>
    </ul>
  </div>
</div> 
</RouterLink>

</template>
<style scoped>
@media (min-width: 756px) and (max-width: 1500px){
  .height-carrousel{
    height: 380px;
  }
  .line-clamp-2 {
    width: 200px
  }
}
@media (min-width: 1501px){
  .height-carrousel{
    height: 450px;
  }
}
@media (max-width: 755px){
  .line-clamp-2 {
    width: 200px
  }
}

</style>