<script>
export default{
    props: {
        contentProp: {
            type: String,
            required: false,
            default: null
        },
        params: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            content: String,
            image: `${this.publicPath}assets/img/bg2.png`
        };
    },
    mounted() {

       for(let i = 0; i< this.params.length; i++){
            if(this.params[i].name == "background"){
                this.image = this.params[i].value
            }
       }

       this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
                this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/bg2.png`;
        });

        if (this.contentProp) {
            this.content = this.contentProp;
        } else {
            this.func.populateParams(this, this.params);
        }


    },
}
</script>

<template>
    <section>
        <div class="bg-center py-5 bg-no-repeat bg-cover text-white first-page" :style="`background-image: url(${image})`">
            <div class="absolute w-2/3 2xl:w-full grid place-items-center mt-9">
                <img class="h-20" :src="`${publicPath}assets/img/logobg_white.png`" />
            </div>
            <div class=" mx-4 lg:mx-56">
                <p v-if="content" class="text-blue-2 mb-20 mt-20 text-left leading-10 bg-white p-8 md:p-20  shadow-md shadow-blue-4 rounded-md detail-section" v-html="content"></p>
            </div>
        </div>
    </section>
</template>

<style>
.detail-section a {
        background-color: #4fbabc;
        color: rgb(255, 255, 255);
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 6px;
        display: inline-block;
}
.detail-section a:hover {
        background-color: white !important;
        color: #4fbabc !important;
        border: 1px solid #4fbabc !important;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 6px;
        display: inline-block;
        transition: all;
        animation-duration: initial;
}
.detail-section p {
    font-weight: initial !important;
}
.detail-section table{
 width: 100% !important;
}

.first-page p ul {
    list-style: circle;
    margin-left: 50px;
}

.detail-section img {
    display: initial;
}

@media (max-width : 735px){
    .detail-section a {
            display: block;
            text-align: center;
        }
        .detail-section ul{
        list-style: circle;
        margin-left: 10px;
        }
    }
</style>