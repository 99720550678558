<script>
import DiscoverCarousel from '@/components/Carousel/DiscoverCarousel.vue';

export default {
    props: {
        params: {
            type: Object,
            required: false,
            default: () => ({})
        },
        child_components: {
            type: Array,
            required: false,
            default: () => ([])
        }
    },
    data() {
        return {
            title: 'Découvrez nos produits',
            tabs : [],
            activeTabIndex: null,
            image: `${this.publicPath}assets/img/bg3.png`,
            selectedMenu: ''
        };
    },
    beforeMount() {
        if(this.tabs.length > 0){
            localStorage.removeItem('selectedMenu')
            localStorage.setItem('selectedMenu', this.tabs[0].titre)
        }
        this.func.populateParams(this, this.params);
        for(const child of this.child_components) {
            if (child.type.name === 'CarouselWithTab') {
                this.activeTabIndex = 0;
                this.tabs.push({titre: child.name});
            }
        }
    },
    methods: {
        setActiveTab(index, titre) {
            this.activeTabIndex = index;
            this.selectedMenu = titre
        },
        isActive(path) {
            return this.$route.path === path;
        },
    },
    computed: {
        carousels() {
            return this.activeTabIndex !== null ? this.child_components[this.activeTabIndex].children: this.child_components[0].children;
        },
    },
    components: {
        DiscoverCarousel
    },
    watch: {
        carousels: {
            deep: true,
            immediate: true,
            handler() {}
        },
        selectedMenu: {
            deep: true,
            immediate: true,
            handler() {}
        }
        
    },
    mounted() {
        /*if(this.tabs.length > 0){
            localStorage.removeItem('selectedMenu')
            localStorage.setItem('selectedMenu', this.tabs[0].titre)
        }*/

        if(this.tabs.length > 0){
            this.selectedMenu = this.tabs[0].titre;
        }
        

        if(this.child_components[0]['parameters'].length > 0){
            this.image = this.child_components[0]['parameters'][0].value
        }

       this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
                this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/bg3.png`;
        }); 

        if(this.isActive('/accueil')){
            this.isHomepage = true;
            this.title = 'Cryopal en chiffres';
        }
    }
}

</script>

<template>
    <section>
        <div class="bg-center py-5 bg-no-repeat bg-cover text-white first-page" :style="`background-image: url('${image}')`">
            <div class="my-5 relative flex mx-12 lg:mx-96 py-12 items-center">
                <div class="flex-grow border-t border-1 border-blue-2"></div>
                    <h3 class="flex-shrink font-bold px-5 text-blue-2">{{ title }}</h3>
                <div class="flex-grow border-t border-1 border-blue-2"></div>
            </div>

            <div class=" mb-14 grid w-full place-items-center" v-if="activeTabIndex !== null">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
                    <li v-for="(tab, index) in tabs"  class="me-2 bg-white text-blue-4 mb-2 transition-all duration-100 ease-out" :class="activeTabIndex == index ? 'active-tab-class': ''"  role="presentation" :key="index">
                        <button @click="setActiveTab(index, tab.titre)" class="inline-block p-4 border-b-2 rounded-t-lg" id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> {{ tab.titre }}</button>
                    </li>
                </ul>
            </div>

            <div id="default-styled-tab-content" >
                <DiscoverCarousel :cards="carousels" :selectedMenu="selectedMenu"/>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .first-page p ul{
        list-style: circle;
        margin-left: 50px;
    }

    #default-styled-tab .active-tab-class{
        background: #61b9c2;
        color: white;
    }
</style>