export default {

    // Function to populate the dynamic parameters of a component
    populateParams : (component, params) => {
        for (const param of params) {
            component[param.name.toLowerCase()] = param.value;
        }
    },

    // Function to check if an image exists
    checkImageExists: (url, callback) => {
        const img = new Image();
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
        img.src = url;
    }

    // Put here all other utility functions that you want to use in multiple places
};