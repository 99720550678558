<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BlueCard from '../Card/BlueCard.vue';

export default {
    data() {
        return {
            title: "Voir également",
            cards: [],
            isHomepage: false,
            image: `${this.publicPath}assets/img/bg3.png`,
            swiperOptions: {
                slidesPerView: 3,
                spaceBetween: 'auto',
                breakpoints: { 
                    320: { slidesPerView: 1, spaceBetween: 10 },
                    770: { slidesPerView: 2, spaceBetween: 50 },
                    771: { slidesPerView: 3, spaceBetween: 100 }
                }
            }
        };
    },
    components: {
        BlueCard,
        Swiper,
        SwiperSlide
    },
    props: {
        params: {
            type: Object,
            required: false,
            default: () => ({})
        },
        child_components: {
            type: Array,
            required: false,
            default: () => ([])
        },
        cardiTeams: {
            type: Array,
            required: false,
            default: () => ([])
        }
    },
    methods: {
        isActive(path) {
            return this.$route.path === path;
        },
    },
    mounted() {

        console.log('parameters'+JSON.stringify(this.params))

        if(this.params.length > 0){
            //console.log('parameters'+JSON.stringify(this.child_components[0]['parameters'][0]))
            this.image = this.params[0].value
            console.log('image'+this.image)
        }

       this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
                this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/bg3.png`;
        }); 

        if(this.isActive('/accueil')){
            this.isHomepage = true;
            this.title = 'Vos besoins';
        }
        for(const component of this.child_components) {
            if(component.type.name === 'BlueCards') {
                let card = {}
                component.parameters.map((param) => {
                    card[param.name.toLowerCase()] = param.value;
                });
                this.cards.push(card);
            }
           
        }
        
        this.cards = this.cards.concat(this.cardiTeams);
        console.log(this.cards)
    },
    setup() {
        // eslint-disable-next-line no-unused-vars
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation,Pagination]
        };
    },
}
</script>

<template>
    <section>
        <div :class="{ 'accueil': isHomepage }" class="bg-center py-10 bg-no-repeat bg-cover" :style="`background-image: url('${image}')`">
            <div class="my-10 relative flex mx-12 lg:mx-96 items-center">
                <div class="flex-grow border-t border-2 border-blue-2"></div>
                    <h3 :class="{ 'text-2xl text-blue-2': isHomepage }"  class="flex-shrink font-bold px-5">{{ title }}</h3>
                <div class="flex-grow border-t border-2 border-blue-3"></div>
            </div>

            <div class="mx-4 lg:mx-24 2xl:mx-48 carrousel-blue">
                <Swiper 
                    :modules="modules"
                    :slides-per-view="swiperOptions.slidesPerView"
                    :space-between="swiperOptions.spaceBetween" 
                    :pagination="{ clickable: true }"
                    :breakpoints="swiperOptions.breakpoints"
                    :loop="true"
                    navigation
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <SwiperSlide v-for="(card, index) in cards" :key="index">
                        <BlueCard :title="card.title" :text="card.text" :url="card.url" :imageUrl="card.image ? `${apiUrl}/media/${card.image}` : `${publicPath}assets/img/slider2.png`" />
                    </SwiperSlide>
                    ...
                </Swiper>
            </div>
        </div>
    </section>
</template>

<style scoped>
.swiper {
    padding-bottom: 5% !important;
}

.swiper-button-next,.ml-16 .swiper-button-prev {
    color: #1CBDBD !important;
    border: 1px solid #1CBDBD !important;
    padding: 0 20px 0 20px;
    border-radius: 50%;
}


.swiper-pagination-bullet {
  background-color: #31B7BC !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #31B7BC !important;
  width: 0.8rem !important;
  height: 0.8rem !important;
  opacity: 1 !important;
}

.swiper.swiper-initialized{
    place-content: center;
    display: grid;
}
.accueil .swiper.swiper-initialized{
    place-content: revert !important;
    display: grid;
}

.swiper-slide:last-child{
    margin-right: 0px !important;
}

@media (max-width: 1200px) {
  .carrousel-blue{
    margin-left: 0px;
    margin-right: 0px
  }
}

@media (max-width: 765px) {
  .swiper {
  padding-bottom: 16% !important;
}
.accueil .swiper-backface-hidden .swiper-slide {
    width: 336px !important;
}
.swiper-backface-hidden .swiper-slide {
    width: 100% !important;
}
.swiper.swiper-initialized{
    display: block;
}
}
</style>