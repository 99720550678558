import axios from "axios";

class Service {
    constructor() {
        this.url = process.env.VUE_APP_API_URL;
    }

    async getPageList() {
        return await axios.get(`${this.url}/page?status=published`).then((response) => {
            if (response.data) {
                return response.data.data ? response.data.data : response.data
            }
            return []
        })
        .catch((error) => {
            console.error("Error on fetching pages: ", error)
            return []
        })
    }

    async getComponentList(pageID) {
        return await axios.get(`${this.url}/component?page_id=${pageID}&no_parent=1&sort=order`).then((response) => {
            if (response.data) {
                return response.data.data ? response.data.data : response.data
            }
            return []
        })
        .catch((error) => {
            console.error("Error on fetching components: ", error)
            return []
        })
    }

    async getGroupList() {
        return await axios.get(`${this.url}/group`).then((response) => {
            if (response.data) {
                return response.data.data ? response.data.data : response.data;
            }
            return [];
        }).catch((error) => {
            console.error("Error on fetching group list: ", error);
            return [];
        })
    }

    async getMenuList(navbar) {
        return await axios.get(`${this.url}/menu?navbar=${navbar}&sort=order`).then((response) => {
            if (response.data) {
                return response.data.data ? response.data.data : response.data;
            }
            return [];
        }).catch((error) => {
            console.error("Error on fetching menu list: ", error);
            return [];
        })
    }

    async sendEmail(data) {
        return await axios.post(`${this.url}/email/contact`, data).then((response) => {
        console.log("Email sent: ", response);
            return response.status === 200;
        }).catch((error) => {
            console.error("Error on sending email: ", error);
            throw error;
        })
    }

    async sendEmailRH(data) {
        return await axios.post(`${this.url}/email/job_application`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }).then((response) => {
        console.log("Email sent: ", response);
            return response.status === 200;
        }).catch((error) => {
            console.error("Error on sending email: ", error);
            throw error;
        })
    }
}

export default Service;