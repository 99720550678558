<script>

export default {
  data() {
    return {
      title: '',
      image: `${this.publicPath}assets/img/formation-bg.png`
    };
  },
  props: {
    titleProp: {
      type: String,
      required: false,
      default: () => null
    },
    imageProp: {
      type: String,
      required: false,
      default: () => null
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
  },
  computed: {

    classBanner(){
      
        return 'md:h-40vh h-28vh bg-no-repeat md:bg-cover bg-contain bg-center'; // Default classes
    }
      
  },
  mounted() {
    if (this.titleProp && this.imageProp) {
      this.title = this.titleProp;
      this.image = this.imageProp;
    } else {
      if (this.params) {
        this.func.populateParams(this, this.params);
        this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
          this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/formation-bg.png`;
        });
      }
    }
  }
}

</script>

<template>

  <div :class="classBanner" :style="`background-image: url('${image}')`">
    <div class="h-full text-white px-10  md:w-60-percent">
      <div class="pt-60 md:ml-16 md:pt-48">
        <div class="font-bold uppercase 2xl:text-6xl text-4xl flex">
          <div>
            <span class="h-60 mb-10">{{ title }}</span>
            <div v-if="title != ''" class=" bg-gradient-to-r from-blue-2 to-blue-4 p-1 w-32 mt-4 mb-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>