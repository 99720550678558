
<script>
    export default {
        data() {
            return {
                publicPath: process.env.BASE_URL,
                closeModal: false,
            };
        },
        methods:{
            closeModalLogin(){
            this.$emit('close');
            }
        }
    }
</script>

<template>
<!-- Main modal -->
<div id="authentication-modal" tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 grid grid-cols-1 gap-2 place-items-center w-full md:inset-0 max-h-full bg-white bg-opacity-55 backdrop-blur-sm">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                <button @click="closeModalLogin" type="button" class="end-2.5 text-blue-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5">
                <div class="flex items-center space-x-3 rtl:space-x-reverse mb-20">
                        <img :src="`${publicPath}assets/img/Logo_Cryopal.png`" class=" h-20 mx-auto " alt="Flowbite Logo" />
                </div>
                <form class="" action="#">
                    <div class=" mb-4">
                        <label for="email" class="block mb-2 text-sm font-medium text-blue-2 dark:text-white">Email</label>
                        <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="nom@compagnie.com" required />
                    </div>
                    <div class=" mb-4">
                        <label for="password" class="block mb-2 text-sm font-medium text-blue-2 dark:text-white">Mot de passe</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                    </div>
                    <div class="flex justify-between mb-10">
                        <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                            </div>
                            <label for="remember" class="ms-2 text-sm font-medium text-blue-2 dark:text-gray-300">Se souvenir de moi</label>
                        </div>
                        <a href="#" class="text-sm text-blue-2 hover:underline dark:text-blue-500">Mot de passe oublié?</a>
                    </div>
                    <button type="submit" class="w-full text-white bg-blue-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-14">Se connecter</button>
                </form>
            </div>
        </div>
    </div>
</div> 

</template>