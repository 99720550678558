<script>
import Service from '@/services';
import HorizontalNavbar from '@/components/Navbar/HorizontalNavbar.vue';
import BannerPage from '@/components/Banner/BannerPage.vue';
import BannerAccueil from '@/components/Banner/BannerAccueil.vue';
import BannerAd from '@/components/Banner/BannerAd.vue';
import DetailSection from '@/components/Section/DetailSection.vue';
import DiscoverSection from '@/components/Section/DiscoverSection.vue';
import SearchSection from '@/components/Section/SearchSection.vue';
import BlueCarousel from '@/components/Carousel/BlueCarousel.vue';
import StatsSection from '@/components/Section/StatsSection.vue'
import ContactSection from '@/components/Section/ContactSection.vue'
import FloatingButton from '@/components/Button/FloatingButton.vue'
import ContactForm from '@/components/Form/ContactForm.vue'
import BannerMap from '@/components/Banner/BannerMap.vue'
import ContactRHForm from '@/components/Form/ContactRHForm.vue'


const service = new Service();

export default {
  data() {
    return {
      components: [],
      isHomepage: false,
      showStatsAndContact: false,
      isDowload: false,
      isContact: false,
      isContactRH: false,
      type: ''
    };
  },
  components: {
    HorizontalNavbar,
    BannerPage,
    DetailSection,
    BlueCarousel,
    DiscoverSection,
    BannerAccueil,
    StatsSection,
    ContactSection,
    SearchSection,
    FloatingButton,
    BannerAd,
    ContactForm,
    BannerMap,
    ContactRHForm
  },
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchComponents();
      }
    }
  },
  mounted() {
    if(this.isActive('/accueil')){
            this.isHomepage = true;
            this.title = 'Vos besoins';
    }
    if(this.isActive('/contactez-nous')){
            this.isContact = true;
            this.scrollToTop()
    }
    if(this.isActive('/postulez-en-ligne-cryopal')){
            this.isContactRH = true;
            this.scrollToTop()
    }

    if(this.isActive('/telechargements/manuels-utilsateurs-cryo')){
      this.isDowload = true;
      this.type= 'manualCryo';
    }
    if(this.isActive('/telechargements/brochures-commerciales')){
      this.isDowload = true;
      this.type = 'brochureCryo'
    }
    if(this.isActive('/telechargements/certificats')){
      this.isDowload = true;
      this.type = 'certificatCryo'
    }


   setTimeout(() => {
          this.showStatsAndContact = true;
      }, 700);
  },
  methods: {
    isActive(path) {
            return this.$route.path === path;
    },

    async fetchComponents() {
      this.components = await service.getComponentList(this.page.id);
    },
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
  },
  async beforeMount() {
    await this.fetchComponents();
  }
}
</script>
<template>
  <HorizontalNavbar />
  <div v-for="component in components" :key="component.id">
    <component :is="component.type.name" :params="component.parameters" :child_components="component.children"/>
  </div>
  <StatsSection v-if="this.isHomepage && this.showStatsAndContact"/>
  <ContactSection v-if="this.isHomepage && this.showStatsAndContact"/>
  <ContactForm v-if="this.isContact"/>
  <ContactRHForm v-if="this.isContactRH"/>
  <BannerMap v-if="this.isContact || this.isContactRH"/>
  <FloatingButton />
</template>
