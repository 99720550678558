import posthog from "posthog-js";

export default {
  install(app) {
    posthog.init(
      process.env.VUE_APP_POSTHOG_API_KEY,
      {
        api_host: "https://us.i.posthog.com",
        autocapture: true,
        capture_pageview: true,
      }
    );

    const userOptedIn = localStorage.getItem('userOptedIn');
    if (userOptedIn === null) {
      localStorage.setItem('userOptedIn', false);
      posthog.opt_out_capturing();
    } else if (userOptedIn === 'true') {
      posthog.opt_in_capturing();
    } else {
      posthog.opt_out_capturing();
    }

    app.config.globalProperties.$posthog = posthog;
  },
};