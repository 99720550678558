<template>
    <button
      v-if="isVisible"
      @click="scrollToTop"
      class=" z-10 fixed bottom-4 right-4 bg-blue-600 text-white bg-gradient-to-tr bg-blue-2 p-3 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none"
    >
      ↑ Remonter en haut
    </button>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
      };
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      toggleVisibility() {
        this.isVisible = window.scrollY > 300;
      },
    },
    mounted() {
      window.addEventListener('scroll', this.toggleVisibility);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.toggleVisibility);
    },
  };
  </script>
  