<script>

export default {
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
                selectOption: [],
                selectedValues: null,
                gamme: '',
                year : '',
                language: '',
                certificate: '',
                files: null,
                loading: false,
                activeTabIndex: null,
                noResult: false,
                selectedValue: ''
        }
    },
    methods: {
        handleChangeOption(e,nameFilter){

            if(nameFilter === "Langue"){
                this.language = e.target.value;
            }

            if(nameFilter === "Gamme"){
                this.gamme = e.target.value;
            }

            if(nameFilter === "Année"){
                this.year = e.target.value;
            }

            if(nameFilter === "Certificats / CGV"){
                this.gamme = e.target.value
            }
            

            console.log(e.target.value)
            console.log('gamme'+this.gamme)
            console.log('langue'+this.language)
        },

        getListFiles(){
                fetch('https://api.cryopal.amweb.cloud/api/v1/document', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if(data){
                        this.files = data;
                    }
                    else{
                        alert('Une erreur est survenue lors de la récupération des données');
                    }
                    
                })
                .catch((error) => {
                    console.error('Unaunthenticated', error);
                });
        },
        getFilesFiltered(){

            this.loading = true;

            fetch('https://api.cryopal.amweb.cloud/api/v1/document?'+(this.gamme ? 'description=' + this.gamme + '&' : '')+''+(this.year ? 'year=' + this.year + '&' : '')+''+(this.language ? 'language=' + this.language + '&' : ''), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if(data){
                        this.noResult = false;
                        this.files = data;
                        setTimeout(() => {
                            this.loading = false;
                         }, 1000);
                    }
                    else{
                        console.log('true');
                        this.noResult = true;
                    }
                    
                })
                .catch((error) => {
                    console.error('Unaunthenticated', error);
                });
        },
        deleteFilter(){
            this.gamme = ''
            this.year = ''
            this.language = ''
            this.selectedValue = '';
            //this.getListFiles();
            this.$refs.mySelect.forEach(selectElement => {
                selectElement.value = selectElement.options[0].value;
            });
            this.files = null
        }
    },
    computed: {
            items() {
                console.log('cardss'+ JSON.stringify(this.cards))
                return this.cards.map((card) => {
                    const item = {};
                    this.selectedValues = card.parameters.map(() => '');
                    card.parameters.map((param,) => {
                        item[param.name.toLowerCase()] = param.value;
                        
                    });
                    return item;
                    
                    
                });
                
            }

        },
}

</script>

<template>
    <div class=" block md:flex md:flex-row w-full place-content-center mt-10">
    <div class=" w-4/5 md:w-1/5 ml-6" v-for="(item, index) in items" :key="index">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 text-blue-2 ">{{ item.inputname }}</label>
        <select ref="mySelect" @change="handleChangeOption($event,item.inputname)" type="text" name="name" id="name" class="bg-gray-50 border text-blue-2 border-blue-4 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-blue-2 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Cryopal" required="">
            <option></option>
            <option v-for="(select, index1) in item.selectvalue.split(',')"   :key="index1" :value="select" class=" text-blue-2">{{ select }}</option>
        </select>
    </div>

     <div class=" ml-6">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 ">.</label>
        <div class="flex">
            <button id="name" @click="getFilesFiltered" class=" w-36  text-white bg-blue-2 hover:text-blue-2 hover:bg-white hover:border hover:border-blue-2 transition-all duration-200 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500">
        <svg v-if="loading" aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-blue-2 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#164194"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
        </svg>
            Rechercher
        </button>
        <button id="name" @click="deleteFilter" class=" w-36 ml-6 text-white bg-blue-2 hover:text-blue-2 hover:bg-white hover:border hover:border-blue-2 transition-all duration-200 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500">
            Effacer
        </button>
        </div>
    
    </div>
</div>
<div class="flex flex-row w-4/5 place-content-center mt-10 flex-wrap mx-auto">
    <div v-for="(file, index) in files" :key="index" class=" p-6 shadow-md border rounded-md w-1/6 cursor-pointer ml-5 mb-5 text-blue-4 border-blue-4 hover:text-blue-2 hover:border-blue-2 transition-all duration-300">
        <a :href="file.url" class=" font-bold cursor-pointer flex" target="_blank"> {{ file.name }} <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
<path fill-rule="evenodd" d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z" clip-rule="evenodd"/>
<path fill-rule="evenodd" d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clip-rule="evenodd"/>
</svg>
</a>
    </div>
    <div v-if="this.noResult" class="">
        <p class=" text-xl text-blue-2 text-center">Pas de résultat</p>
    </div>
</div>  
</template>