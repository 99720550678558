<script>

import SearchComponent from '@/components/Section/SearchComponent.vue';

export default{
    props: {
        type : String,
        params: {
            type: Object,
            required: false,
            default: () => ({})
        },
        child_components: {
            type: Array,
            required: false,
            default: () => ([])
        }

    },
    data() {
            return {
                gamme: '',
                year : '',
                language: '',
                files: null,
                loading: false,
                inputsArray : [],
                activeTabIndex: null,
                noResult: false,
                image: `${this.publicPath}assets/img/bg3.png`
                
            };
    },
    beforeMount() {
        this.func.populateParams(this, this.params);
        for(const child of this.child_components) {
            console.log('name'+child.type.name);
            if (child.type.name === 'Telechargement') {
                this.activeTabIndex = 0;
                this.inputsArray.push({titre: child.name});
            }
        }
        console.log(this.inputsArray)
    },
    computed: {
        inputs() {
            return this.child_components;
        },
    },
    components: {
        SearchComponent
    },
    methods: {
        FilteredFiles(){

        },

    },
    watch: {
        files: {
            deep: true,
            immediate: true,
        },
        inputs: {
            deep: true,
            immediate: true,
            handler() {}
        },
    },
    mounted(){
        //this.getListFiles();
    }

}
</script>

<template>
    <section>
        <div class="bg-center py-5 bg-no-repeat bg-cover text-white first-page" :style="`background-image: url('${image}')`">
            <div id="default-styled-tab-content">
                <SearchComponent :cards="inputs"/>
            </div>
        </div>
    </section>
</template>