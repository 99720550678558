<script>
import AsideNavbar from '@/components/Navbar/AsideNavbar.vue';


export default {
  data() {
    return {
      title: 'Banner Page',
      image: `${this.publicPath}assets/img/formation-bg.png`,
      text : 'Description'
    };
  },
  components: {
    AsideNavbar
  },
  props: {
    titleProp: {
      type: String,
      required: false,
      default: () => null
    },
    imageProp: {
      type: String,
      required: false,
      default: () => null
    },
    textProp: {
      type: String,
      required: false,
      default: () => null
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mounted() {
    if (this.titleProp && this.imageProp && this.textProp) {
      this.title = this.titleProp;
      this.image = this.imageProp;
      this.text = this.textProp;
    } else {
      if (this.params) {
        this.func.populateParams(this, this.params);
        this.func.checkImageExists(`${this.apiUrl}/media/${this.image}`, (exists) => {
          this.image = exists ? `${this.apiUrl}/media/${this.image}` : `${this.publicPath}assets/img/formation-bg.png`;
        });
      }
    }
  }
}
</script>

<template>
  <div class="h-screen md:h-81 bg-no-repeat bg-cover" :style="`background-image: url('${publicPath}assets/img/CRYOPAL_photo.png')`">
    <AsideNavbar />
    <div class="h-full text-white px-10 content-center">
      <div class="lg:px-24 lg:w-60-percent">  tr
        <div class="font-bold block md:flex">
          <div>
            <h4 class="text-8xl text-blue-2 md:text-white">{{ title }}</h4>
            <div class="bg-gradient-to-r h-3 from-blue-2 to-blue-4 p-1 w-32 mt-4"></div>
            <p class="ml-20 text-blue-2 md:text-white text-xl mt-10">{{ text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>