import Service from "@/services";
import { createWebHistory, createRouter } from "vue-router"
import DynamicPage from "@/components/Page/DynamicPage.vue";

const fetchRoutes = async () => {
  try {
    const service = new Service();
    const pages = await service.getPageList();

    let routes = [];
    pages.forEach(page => {
      const parentRoute = {
        path: page.group ? `/${page.group.slug}/${page.slug}` : `/${page.slug}`,
        name: page.id,
        component: page.children.length > 0 ? undefined : DynamicPage,
        props: page.children.length > 0 ? undefined : { page },
        meta:  {
          breadcrumb: [
            { name: page.title, link: page.group ? `/${page.group.slug}/${page.slug}` : `/${page.slug}`}
          ]
        }
      };

      if (page.children.length > 0) {
        parentRoute.children = page.children.map(child => ({
          path: child.slug,
          name: child.id,
          component: DynamicPage,
          props:  { page: child },
          meta:  {
            breadcrumb: [
              { name: child.title, link: child.group ? `/${child.group.slug}/${child.slug}` : `/${child.slug}`}
            ]
          }
        }));
        parentRoute.children.push({
          path: "",
          name: page.id,
          component: DynamicPage,
          props: { page },
          meta:  {
            breadcrumb: [
              { name: page.title, link: page.group ? `/${page.group.slug}/${page.slug}` : `/${page.slug}`}
            ]
          }
        });
      }

      //console.log('parentRoutes'+parentRoute)
      routes.push(parentRoute);
    });

    return routes;
  }
  catch (error) {
    console.error(error);
  }
};

const routesPromise = fetchRoutes().then(routes => {
  return createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: "/recherche",
        name: "recherche",
        component: () => import("@/components/Page/SearchPage.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/components/Page/NotFound.vue"),
      },
      ...routes
    ],
  });
});


export default routesPromise
