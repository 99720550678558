<script>

export default{
    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    props: {
        number: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    },
}

</script>

<template>
  <div class="relative bg-clip-border rounded-xl text-white w-full  p-8 m-auto transition duration-300 ease-in-out">
  <div class="p-0">
        <img :src="`${publicPath}assets/img/stats/${icon}`" />
        <p class="block font-sans antialiased leading-relaxed text-center text-2xl mt-14">
            {{ number }}+ 
            <br/>{{ text }}
        </p>
  </div>
</div> 

</template>