import App from '@/App.vue'
import '@/index.css'

import { createApp } from 'vue'
import routesPromise from '@/router'

import func from '@/utils/func';
import posthog from "@/plugins/posthog"; 



routesPromise.then(router => {
    const app = createApp(App);

    app.config.globalProperties.publicPath = process.env.BASE_URL;
    app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL;
    app.config.globalProperties.func = func;

    app.use(router);
    app.use(posthog);

    app.mount('#app');
})
