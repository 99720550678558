<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default {
    data() {
        return {
            swiperOptions: {
                slidesPerView: 3,
                spaceBetween: 'auto',
                breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                770: {
                    slidesPerView: 2,
                    spaceBetween: 50
                },

                771: {
                    slidesPerView: 3,
                    spaceBetween: 110
                }
                }
            },
            isMore: false,
            descriptions: {},
            processedDescriptions: []
        };
    },
    props: {
        cards: {
            type: Array,
            required: true
        },
        selectedMenu: {
            type: String,
            required: false
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        /*gotoPage(name){
            this.$router.push({ name: `pageInternePath`, params:{path: name } });
        }*/
        gotoPage(category, page){
          this.$router.push({ path: `/${category}/${page}` });
          this.openMenu = false;
        },
        getUrl(url) {
            return url ? '/' + url : '/page-inexistant'; // Replace '/default-path' with your fallback URL
        },
        clickProduct(name, link){
            localStorage.setItem('childrenMenu', name);
            localStorage.setItem('childrenLinkMenu', link)
        },
        test(){
            const descriptionsTest = {};

            for(let i = 0; i < this.items.length; i++){
                descriptionsTest[this.items[i].title] = this.items[i].description
            }
            /*this.items.forEach(item => {
                descriptionsTest[item.id] = this.getDescription(item.description); // Assuming item.id is unique
            });*/

            return descriptionsTest;
        },
        getDescription(text){
            if(text){
           // Create a temporary DOM element to parse the HTML string
           const parser = new DOMParser();
           const doc = parser.parseFromString(text, 'text/html');

           // Get all the <h1> and <p> tags
           const titles = doc.querySelectorAll('h1');
           
           titles.forEach((heading) => {
           // Get the text content of the <h1> tag (e.g., 'Tube 1')
           const tube = heading.textContent.replace(/’/g, "'");

           // Initialize a variable to hold the associated <p> descriptions
           let description = '';

           // Get the next sibling elements until the next <h1> or the end of the document
           let sibling = heading.nextElementSibling;

           while (sibling && sibling.tagName !== 'H1') {
               if (sibling.tagName === 'P') {
                   description += sibling.outerHTML; // Add the <p> content to the description
               }
               sibling = sibling.nextElementSibling;
           }

           this.descriptions[tube] = description;

           });

           return Object.keys(this.descriptions)
                .filter(key => this.selectedMenu && this.selectedMenu === key)
                .reduce((result, key) => {
                result[key] = this.descriptions[key];
                return result;
                }, {});
            }  
       },
       processAllDescriptions() {
            // Loop through all items and store their processed descriptions
            this.processedDescriptions = this.items.map((item) => {
                return this.getDescription(item.description);
            });
        }, 
    },
    setup() {
        // eslint-disable-next-line no-unused-vars
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination]
        };
    },
    computed: {
        items() {
            return this.cards.map((card) => {
                const item = {};
                card.parameters.map((param) => {
                    item[param.name.toLowerCase()] = param.value;
                });
            
                return item;
            });
        },
    },
    watch: {
        cards: {
            deep: true,
            immediate: true,
            handler() {
                if(this.cards.length > 3){
                    this.isMore = true;
                }
                else{
                    this.isMore = false;
                }
            }
        },
        selectedMenu: {
            deep: true,
            immediate: true,
            handler() {
                this.processAllDescriptions()
            }
        }
    },
    mounted(){
        this.processAllDescriptions();
    }
}
</script>

<template>

    <div class="items-center">
        <div :class="{ 'isMore': isMore }" class="h-50 lg:w-5/6 mx-auto discover-carousel ">
            <Swiper 
                :modules="modules" 
                :slides-per-view="swiperOptions.slidesPerView" 
                :space-between="swiperOptions.spaceBetween" 
                :pagination="{ clickable: true }" 
                :breakpoints="swiperOptions.breakpoints"
                :loop="true"
                navigation
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
                <SwiperSlide v-for="(item, index) in items" :key="index">
                    <div class="text-center text-white transition duration-300 ease-in-out hover:scale-110">
                        <a @click="gotoPage('nos-produits',card.name)"><img :src="`${apiUrl}/media/${item.image}`" alt="product" class="w-3/4 mx-auto lg:w-full h-64 object-cover rounded-md"></a>
                        <a href=""><h3 class="text-lg font-semibold mt-2 text-blue-2">{{ item.title }}</h3></a>
                        <div class=" absolute w-full h-64 top-0 left-0 bg-white opacity-0 z-10 transition-opacity duration-300 hover:opacity-100 grid place-items-center text-blue-2 ">
                            <div  v-for="(description, key) in processedDescriptions[index]"  :key="key">
                                <p class=" mt-10 font-bold" v-html="description"></p>
                            </div>
                            <RouterLink :to="item.url.startsWith('/') ? item.url : '/' + item.url" @click="clickProduct(item.title, item.url)" class="bg-blue-4 text-white p-3 rounded-sm">En savoir plus</RouterLink>
                 
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>

</template>

<style>
.swiper {
  padding-bottom: 5% !important;
  padding-left: 7%;
    padding-right: 7%;
}

.discover-carousel .swiper-pagination-bullet {
  background-color: #73b6c2 !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
  opacity: 1 !important;
}

.discover-carousel .swiper-pagination-bullet-active {
  background-color: #73b6c2 !important;
  width: 0.8rem !important;
  height: 0.8rem !important;
  opacity: 1 !important;
}

.discover-carousel .swiper-button-next, .discover-carousel .swiper-button-prev {
  color: #73b6c2 !important;
  border: 1px solid #73b6c2 !important;
  padding: 0 20px 0 20px;
  border-radius: 50%;
}

.swiper-button-next {
  right: 0;
}

.swiper.swiper-initialized{
    place-content: center;
    display: grid;
}
.isMore .swiper.swiper-initialized{
    place-content: revert !important;
    display: grid;
}
.swiper-slide:last-child{
    margin-right: 0px !important;
}

@media (max-width: 765px) {
    .swiper {
        padding-bottom: 16% !important;
    }
    .swiper.swiper-initialized{
    display: block;
}
}
</style>