<script>

import StatCard from '@/components/Card/StatCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default {
  components: {
    Swiper,
    SwiperSlide,
    StatCard
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      stats: [
        {
          number: '90',
          text: 'Professionnels',
          icon: '90_professionnels.png'
        },
        {
          number: '55',
          text: 'Années d\'expérience',
          icon: '55_annees_experience.png'
        },
        {
          number: '90',
          text: 'Pays',
          icon: '90_pays.png'
        }

      ],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          770: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      }
    }
  },
  setup() {
    // eslint-disable-next-line no-unused-vars 
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    const onUpdateSwiper = (swiper) => {
      swiper.update();
    };

    return {
      onSwiper,
      onSlideChange,
      onUpdateSwiper,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  }

}
</script>

<template>

  <div class="content-center bg-gradient-to-br from-blue-2 to-blue-4 ">
    <div class="flex py-12 items-center w-60-percent mx-auto">
      <div class="flex-grow border-t border-1 border-blue-4"></div>
      <span class="flex-shrink mx-6 text-white font-bold text-2xl">Cryopal en chiffres</span>
      <div class="flex-grow border-t border-1 border-blue-2"></div>
    </div>
    <div>
      <swiper 
        :modules="modules" 
        :slides-per-view="swiperOptions.slidesPerView" 
        :space-between="swiperOptions.spaceBetween" 
        navigation
        :pagination="{ clickable: true }"
        :breakpoints="swiperOptions.breakpoints"
        @update:swiper="onUpdateSwiper"
        @swiper="onSwiper"
        @slideChange="onSlideChange">

        <swiper-slide v-for="(stat, idx) of stats" :key="idx">
          <StatCard :number="stat.number" :text="stat.text" :icon="stat.icon" />
        </swiper-slide>

        ...
      </swiper>
    </div>
  </div>

</template>

<style scoped>
.swiper-pagination-bullet {
  background-color: white !important;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper-button-next, .swiper-button-prev {
  color: white !important;
  border: 1px solid white !important;
}
</style>